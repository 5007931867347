import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo } from 'react';
import { Box } from '@mui/material';
import { getFeatureParameter } from '../../../../features';
import DashboardCards from '../../../../components/DashboardCard/DashboardCards';
import { CustomDataGrid } from '../../../../components/CustomDataGrid';

function FeatureParameter({
  orgId, packageId, isShare, tenantId, accountOrgId,
}) {
  const dispatch = useDispatch();

  useEffect(() => {
    if (orgId && packageId) {
      dispatch(getFeatureParameter({
        orgId, packageId, isShare, tenantId, accountOrgId,
      }));
    }
  }, [orgId, packageId, tenantId, accountOrgId]);

  const featureParameterData = useSelector((state) => state.featureParameter);
  const { loading, error, data } = featureParameterData;

  const rows = useMemo(() => {
    const modifiedData = data?.map((row) => ({
      fullName: row?.sfFma__FeatureParameter__r?.sfFma__FullName__c,
      value: row?.sfFma__Value__c,
      dataType: row?.sfFma__FeatureParameter__r?.sfFma__DataType__c,
      dataFlow: row?.sfFma__FeatureParameter__r?.sfFma__DataFlowDirection__c,
    }));
    return modifiedData ?? [];
  }, [data]);

  const columns = [
    {
      field: 'fullName',
      headerName: 'Full Name',
      flex: 1,
    },
    {
      field: 'value',
      headerName: 'Value',
      flex: 0.4,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'center-align--header',
    },
    {
      field: 'dataType',
      headerName: 'Data Type',
      flex: 0.6,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'center-align--header',
    },
    {
      field: 'dataFlow',
      headerName: 'Data Flow',
      flex: 1,
      filterable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'center-align--header',
    },
  ];

  if (!loading && !error && data?.length) {
    return (
      <Box>
        <DashboardCards title="Feature Parameters">
          <CustomDataGrid columns={columns} rows={rows} />
        </DashboardCards>
      </Box>
    );
  }

  return null;
}

export default FeatureParameter;
