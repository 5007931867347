import { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLocalStorage, parse, setLocalStorage } from '../../../../utils/methods';
import { getPackageList } from '../../../../features';
import useAuthorization from '../../../../hooks/useAuthorization';

const usePackageSelection = (object = {}) => {
  const { pkgId, isShare } = object;
  const dispatch = useDispatch();
  const { userInfo } = useAuthorization();

  // check demo package
  const isHabileLabsDemo = useMemo(() => {
    return userInfo?.tenantId === 'habilelabs_1291db';
  }, [userInfo]);

  const activeSelectedPackage = getLocalStorage('activePackage');
  const [activePackage, setActivePackage] = useState(activeSelectedPackage ? JSON.parse(activeSelectedPackage) : '');
  const [packageList, setPackageList] = useState({
    packages: [],
    error: '',
    loading: true,
  });

  const [initialFetchDone, setInitialFetchDone] = useState(false);
  useEffect(() => {
    if (!initialFetchDone && (!activePackage || !packageList?.packages?.length) && !isShare) {
      dispatch(getPackageList({ isShare }));
      setInitialFetchDone(true);
    }
  }, [activePackage, packageList?.packages, isShare, initialFetchDone, dispatch]);
  const packageListData = useSelector((state) => state.packageList);

  const setPackageListData = () => {
    const filterPackageMenu = packageListData?.packageMenuList ? packageListData.packageMenuList.filter((obj) => obj.isEnabled) : [];

    // change demo package name
    const filterPackageMenuList = isHabileLabsDemo && filterPackageMenu?.length > 0
      ? filterPackageMenu.map((obj) => {
        if (obj.packageId === '03324000000DD19') {
          return {
            ...obj,
            packageName: 'APP Insights Demo',
            packageLabel: 'APP Insights Demo',
          };
        }
        return obj;
      })
      : filterPackageMenu;

    setPackageList({
      ...packageList,
      packages: filterPackageMenuList,
      error: packageListData.error,
    });
    if (filterPackageMenuList && filterPackageMenuList?.length && !isShare) {
      if (pkgId) {
        const activePkgData = filterPackageMenuList.filter((obj) => obj.packageId === pkgId);
        setActivePackage(...activePkgData);
        setLocalStorage('activePackage', JSON.stringify(...activePkgData));
      } else if (activeSelectedPackage) {
        setActivePackage(JSON.parse(activeSelectedPackage));
      } else {
        setActivePackage(filterPackageMenuList[0]);
        setLocalStorage('activePackage', JSON.stringify(filterPackageMenuList[0]));
      }
    }
  };

  const setActivePackageData = () => {
    if (activeSelectedPackage) {
      setActivePackage(JSON.parse(activeSelectedPackage));
    }
  };

  const handleDropdownChange = (event) => {
    const value = parse(event.target.value);
    setActivePackage(value);
    setLocalStorage('activePackage', JSON.stringify(value));
  };

  useEffect(() => {
    setPackageListData();
  }, [packageListData?.packageMenuList]);

  useEffect(() => {
    setActivePackageData();
  }, [activeSelectedPackage]);

  return { packageList, activePackage, handleDropdownChange };
};

export default usePackageSelection;
